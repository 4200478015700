//background_variables :
// $finanzas:#FF5757;
//$transporte:#C9E265;
//$finanzas : #E22F22;
$finanzas: rgb(180, 26, 26);
// $finanzas: "../media-demo/f1.png";
$maestros: rgb(180, 26, 26); //#C9E265;
$configuracion: #6c9136; //#C9E265;
$transporte: #6c9136; //#C9E265;
$aduanas: #6d88ff;
$carga: #ff914d;
// $sintad-font:'Courier';
// $sintad-font:'Poppins, Helvetica, sans-serif';
// $sintad-font:'Soho Gothic Pro';
// $sintad-font:'Georgia';
// $sintad-font:'Verdana';
$sintad-font: "Soho Gothic Pro";
//  $sintad-font:'Open Sans', sans-serif;
// $sintad-font:'Andale Mono';
//  $sintad-font:'Menlo';
// $sintad-font:'Monaco';
// $sintad-font:'Apple Symbols';
// $sintad-font:'AppleGothic';
// $sintad-font:'AppleMyungjo';
// $sintad-font:'Noteworthy';
.aduanas {
    background-color: $aduanas;
}

.carga {
    background-color: $carga;
}

.finanzas {
    background-color: $finanzas;
    // background-image: url("/a1.png");
}

.transporte {
    background-color: $transporte;
}

.configuracion {
    background-color: $configuracion;
}

.maestros {
    background-color: $maestros;
}

.selection>span {
    height: 32.0625px;
    border: 1px solid #e2e5ec;
}


/** Start select 2 */

.selection>span:focus {
    // color: #495057;
    background-color: #fff;
    border-color: #9aabff !important;
    outline: 0;
}

.selection>span>.select2-selection__arrow {
    height: 30px !important;
}

// .select2-hidden-accessible{
//  display: none!important;
// }
.select2-dropdown {
    background-color: white;
    border: 1px solid #e2e5ec !important;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    // left: -100000px;
    width: 100%;
    //   z-index: 10;
    margin-top: 2px !important;
}

.select2-search>.select2-search__field {
    border: 1px solid #e2e5ec !important;
}

.select2-search>.select2-search__field:focus {
    // color: #495057;
    background-color: #fff;
    border-color: #9aabff !important;
    outline: 0;
}

// .select2-dropdown > .select2-results {
//   // text-align: center;
// }
.select2-dropdown {
    margin-top: 10px;
    border-radius: 3.25px !important;
    box-shadow: 0 1px 5px #959595;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 4px !important;
}


/** End select 2 */


/**Start Calendar*/

// FIXME /deep/
::ng-deep .dropdown-menu {
    position: absolute;
    top: 10px !important;
    left: 0px;
    will-change: transform;
    transform: translate(353px, 337px);
    box-shadow: 0 1px 5px #959595;
}


/** End Calendar*/


/**Start Multiselect*/

.multiselect-dropdown>div>.dropdown-btn {
    height: 32.0625px !important;
    display: inline-block;
    border: 1px solid #e2e5ec !important;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
}

.multiselect-dropdown>div>.dropdown-btn:focus {
    // color: #495057;
    background-color: #fff;
    border-color: #9aabff !important;
    outline: 0;
}

.dropdown-btn>span>.dropdown-down {
    border-color: transparent transparent #888 transparent;
    border-style: solid !important;
    border-width: 5px 4px 0 4px !important;
    height: 0 !important;
    left: 93% !important;
    margin-left: -4px !important;
    margin-top: -2px !important;
    position: absolute !important;
    top: 50% !important;
    width: 0 !important;
}

.dropdown-btn>span>.dropdown-up {
    border-color: transparent transparent #888 transparent;
    border-style: solid !important;
    border-width: 0px 4px 5px 4px !important;
    height: 0 !important;
    left: 93% !important;
    margin-left: -4px !important;
    margin-top: -2px !important;
    position: absolute !important;
    top: 50% !important;
    width: 0 !important;
}


/** End Multiselect */


/** Start ag-grid */

.ag-theme-balham {
    font-family: $sintad-font;
    color: #646c9a;
}

no-op.ag-pivot-off {
    font-family: $sintad-font;
}

no-op.ag-header-viewport,
no-op.ag-pinned-right-header,
no-op.ag-paging-panel {
    color: #646c9a !important;
}


/** End ag-grid */


/** Start Acordion */

.mat-expansion-panel-header-title {
    font-family: $sintad-font;
}

.mat-expansion-panel-body {
    font-family: $sintad-font;
}


/** End Acordion */


/** Start Button */

button {
    font-family: $sintad-font;
}


/** End Button */

// FIXME /deep/
fieldset.fieldset-border {
    border: 1px solid #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 0 0 !important;
}

// FIXME /deep/
legend.fieldset-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto !important;
    padding: 0 10px !important;
    border-bottom: none !important;
}

.mat-expansion-panel {
    color: #646c9a;
}

// FIXME /deep/
::ng-deep {
    .ag-body-viewport-wrapper.ag-layout-normal {
        overflow-x: scroll !important;
        overflow-y: scroll !important;
    }
     ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

$color-text: #646c9a;
.mat-expansion-panel-header-title {
    color: $color-text !important;
}