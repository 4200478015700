ng-select.ng-select-invalid.ng-select-touched {
	.ng-select-container {
		border-color: #dc3545;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
		// padding-right: calc(1.5em + 1.3rem);
		padding-right: calc(0.55em + 1.3rem);
	}
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333 !important;
    background-color: #d6edff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #e8f5ff !important;
    color: #333 !important;
}

.ng-select-red {
	div.ng-select-container {
		background: red !important;
		color: #ffffff !important;
	}
}

.ng-select-green {
	div.ng-select-container {
		background: green !important;
		color: #ffffff !important;
	}
}

.ng-select-orange {
	div.ng-select-container {
		background: orange !important;
		color: #ffffff !important;
	}
}


.ng-multiselect-checkbox {
	color: #000 !important;
    font-family: "Soho Gothic Pro";
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        margin-bottom: -4px !important;
        margin-top: -8px !important;
    }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	background-image: none;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

.form-control:focus,
.form-control:active {
	border-color: #007eff;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !important;
}

.btn:focus:not(.btn-elevate) {
	border-color: #007eff !important;
	box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.1), 0 0 0px 5px rgba(0, 126, 255, 0.2) !important;
}

.swal2-popup .swal2-styled:focus {
	border-color: #007eff !important;
	box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.1), 0 0 0px 5px rgba(0, 126, 255, 0.2) !important;
}