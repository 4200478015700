
.kt-timeline-3 .kt-timeline-3__inner {
	.kt-timeline-3__item {
		min-height: 30px;
		border-left: 3px solid #586bd8;
		padding-left: 15px;

		&::before {
			display: none;
		}

		.kt-timeline-3__item-time {
			padding-top: 0;
			position: static;
		}
	}

	.kt-timeline-3__item-child {
		margin-left: 27px;
		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
		.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent
			.mat-checkbox-ripple
			.mat-ripple-element {
			background-color: rgba(54, 163, 247, 0.26);
		}
	}
}



// timeline
.kt-list-timeline {
	.kt-list-timeline__items {
		position: relative;
		padding: 0;
		margin: 0;
		&:before {
			position: absolute;
			display: block;
			content: "";
			width: 1px;
			height: 100%;
			top: 0;
			bottom: 0;
			left: 3px;
			background-color: #ebedf2;
		}
		.kt-list-timeline__item {
			position: relative;
			display: table;
			table-layout: fixed;
			width: 100%;
			padding: 6px 0;
			margin: 5px 0;
			&:first-child {
				padding-top: 0;
				margin-top: 0;
			}
			.kt-list-timeline__badge {
				text-align: left;
				vertical-align: middle;
				display: table-cell;
				position: relative;
				width: 20px;
				&:before {
					position: absolute;
					display: block;
					content: "";
					width: 7px;
					height: 7px;
					left: 0;
					top: 50%;
					margin-top: -3.5px;
					border-radius: 100%;
					background-color: #ebedf2;
				}
				&--success:before {
					background-color: #34bfa3;
				}
				&--metal:before {
					background-color: #c4c5d6;
				}
				&--danger:before {
					background-color: #f4516c;
				}
			}
			&:first-child:before {
				display: block;
				content: "";
				width: 1px;
				height: 50%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 3px;
				background-color: #fff !important;
			}
			&:last-child:before {
				display: block;
				content: "";
				width: 1px;
				height: 50%;
				position: absolute;
				top: 50%;
				bottom: 0;
				left: 3px;
				background-color: #fff !important;
			}
			.kt-list-timeline__text {
				display: table-cell;
				text-align: left;
				vertical-align: middle;
				width: 100%;
				padding: 0 5px 0 0;
				font-size: 1rem;
				.kt-badge {
					font-size: 0.8rem;
					line-height: 20px;
					min-height: 20px;
					min-width: 20px;
					width: auto;
					height: auto;
					vertical-align: middle;
					text-align: center;
					display: inline-block;
					padding: 0 7px;
					border-radius: 0.75rem;
				}
			}
		}
	}
	&--user-role {
		.kt-list-timeline__items {
			&:before {
				background-color: transparent;
			}
		}
	}
}




// badge
.kt-badge {
	background: #eaeaea;
	color: #444;
	font-size: 0.8rem;
	line-height: 20px;
	min-height: 20px;
	min-width: 20px;
	vertical-align: middle;
	text-align: center;
	display: inline-block;
	padding: 0 3px;
	border-radius: 0.75rem;
	&--wide {
		letter-spacing: 0.6px;
		padding: 1px 10px;
		border-radius: 0.75rem;
	}
	&--success {
		background-color: #34bfa3;
		color: #fff;
	}
	&--metal {
		background-color: #c4c5d6;
		color: #fff;
	}
	&--danger {
		background-color: #f4516c;
		color: #fff;
	}
}

.kt-list-timeline__badge--primary:before {
	background-color: #5867dd !important;
	color: #fff;
}




.kt-form.kt-form--group-seperator-dashed .kt-form__group {
	border-bottom: 1px dashed #ebedf2;
}

.kt-timeline-3 .kt-timeline-3__items .kt-timeline-3__item {
	display: table;
	position: relative;
	margin-bottom: 1.75rem;
}



// Role permissions
.kt-timeline-3 {
	.kt-timeline-3__items--rolePermissions {
		.kt-timeline-3__item {
			display: block !important;
			border-left: 0;
			margin-bottom: 0 !important;
			&-child {
				border-left: 0;
			}
			.kt-timeline-3__item-time {
				position: static !important;
			}
		}
	}
}
.kt-bg-grey {
	background-color: #f7f8fa;
}
.kt-border-bottom-grey {
	border-bottom: 1px solid #eceff5;
}
.kt-py-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}